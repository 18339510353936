import React, { useContext, useEffect, useRef } from "react";
import { ConfigContext } from "../../AppConfigProvider";
import { isDev } from "../../../config/env";
import { adUnit } from "./GoogleAdUnit.css";
import { GoogleAdUnitID, googleAdUnitsConfig } from "./google-ad-units-config";

interface GoogleAdSlotProps {
    id: string;
    slotId: string;
    sizeMapping: googletag.SizeMappingArray;
}

const GoogleAdSlot: React.FC<GoogleAdSlotProps> = ({
    id,
    slotId,
    sizeMapping,
}) => {
    const slotRef = useRef<googletag.Slot | null>(null);
    const sizes = sizeMapping.reduce<googletag.MultiSize>((s, i) => {
        if (Array.isArray(i[1])) {
            for (const size of i[1]) {
                if (typeof size !== "number") s.push(size);
            }
        }
        return s;
    }, []);
    useEffect(() => {
        try {
            window.googletag = window.googletag || { cmd: [] };
            googletag.cmd.push(() => {
                slotRef.current = googletag.defineSlot(slotId, sizes, id);
                slotRef.current?.defineSizeMapping(sizeMapping);
                slotRef.current?.addService(googletag.pubads());
                googletag.display(id);
            });
        } catch (e) {
            console.error(`AdUnit ${id} init error`);
            console.error(e);
        }
        return () => {
            if (
                window.googletag &&
                !!window.googletag.destroySlots &&
                slotRef.current
            ) {
                googletag.destroySlots([slotRef.current]);
            }
        };
    }, [id, slotId]);
    return <div id={id} className={adUnit} />;
};

interface Props {
    id: GoogleAdUnitID;
}

const GoogleAdUnit: React.FC<Props> = ({ id }) => {
    const { googleAdUnitRoot } = useContext(ConfigContext);
    if (!googleAdUnitRoot || isDev) return null;

    return (
        <GoogleAdSlot
            id={`ad-slot-${id}`}
            slotId={`${googleAdUnitRoot}/${id}`}
            sizeMapping={googleAdUnitsConfig[id]}
        />
    );
};

export default GoogleAdUnit;
