export type GoogleAdUnitID =
    | "news-page-under-toc"
    | "news-page-in-text-1"
    | "news-page-aside-banner"
    | "moon-calendar-in-text-1"
    | "moon-calendar-under-toc"
    | "quiz-page-bottom-banner";
export const googleAdUnitsConfig: Record<
    GoogleAdUnitID,
    googletag.SizeMappingArray
> = {
    "news-page-under-toc": [
        [
            [600, 0],
            [
                [580, 400],
                [480, 320],
                [468, 60],
            ],
        ],
        [
            [0, 0],
            [
                [320, 480],
                [336, 280],
                [300, 250],
                [320, 100],
                [300, 100],
                [320, 50],
                [300, 50],
                [300, 31],
            ],
        ],
    ],
    "news-page-in-text-1": [
        [
            [600, 0],
            [
                [580, 400],
                [480, 320],
                [468, 60],
            ],
        ],
        [
            [0, 0],
            [
                [320, 480],
                [336, 280],
                [300, 250],
                [300, 100],
                [300, 75],
                [300, 50],
                [320, 50],
                [300, 31],
            ],
        ],
    ],
    "news-page-aside-banner": [
        [
            [992, 0],
            [
                [160, 600],
                [120, 600],
                [120, 240],
            ],
        ],
        [
            [600, 0],
            [
                [580, 400],
                [480, 320],
                [468, 60],
            ],
        ],
        [
            [0, 0],
            [
                [320, 480],
                [336, 280],
                [300, 250],
                [320, 100],
                [300, 75],
                [320, 50],
                [300, 50],
            ],
        ],
    ],
    "moon-calendar-in-text-1": [
        [
            [600, 0],
            [
                [580, 400],
                [480, 320],
                [468, 60],
            ],
        ],
        [
            [0, 0],
            [
                [320, 480],
                [336, 280],
                [300, 250],
                [300, 50],
                [320, 100],
                [300, 100],
                [300, 75],
                [320, 50],
                [300, 31],
            ],
        ],
    ],
    "moon-calendar-under-toc": [
        [
            [600, 0],
            [
                [580, 400],
                [480, 320],
                [468, 60],
            ],
        ],
        [
            [0, 0],
            [
                [320, 480],
                [336, 280],
                [300, 250],
                [320, 100],
                [300, 100],
                [300, 75],
                [320, 50],
                [300, 31],
                [300, 50],
            ],
        ],
    ],
    "quiz-page-bottom-banner": [
        [
            [600, 0],
            [
                [580, 400],
                [480, 320],
                [468, 60],
            ],
        ],
        [
            [0, 0],
            [
                [300, 600],
                [320, 480],
                [336, 280],
                [300, 250],
                [320, 100],
                [300, 100],
                [300, 75],
                [320, 50],
                [300, 31],
                [300, 50],
            ],
        ],
    ],
};
